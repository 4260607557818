import styled from "styled-components"
import tw from "twin.macro"

export const HeaderWrapper = styled.header`
    ${tw`py-6 bg-white`}
`

export const LargeHeader = styled.div`
    ${tw`hidden sm:block`}

    ul {
        ${tw`flex justify-center items-center`}

        li {
            ${tw`px-6`}
            
            a {
                ${tw`text-xl`}

                &.active {
                    ${tw`text-primary`}
                }
            }
        }
    }
`

export const SmallHeader = styled.div`
    ${tw`sm:hidden`}

    .burger-icon-wrp {
        ${tw`relative`}
        z-index: 1;
    }

    .mobile-menu-content-wrp {
        ${tw`absolute w-full h-screen top-0 left-0 transition-all duration-300 ease-linear bg-primary`}
        transform: translateY(-100%);

        &.menu-opened {
            transform: none;
        }
    }

    .mobile-menu-content {
        ${tw`h-full flex items-center`}

        ul {
            ${tw`w-full px-6 py-6 text-center`}
    
            li {
                ${tw`py-4`}

                a {
                    ${tw`text-xl text-white`}

                    &.active {
                        ${tw`text-secondary`}
                    }
                }
            }
        }
    }
`

export const StyledBurger = styled.button`
    ${tw`flex flex-col justify-around bg-none cursor-pointer p-0 w-7 h-7 ml-auto focus:outline-none`}
    border: none;
    z-index: 1;

    div {
        ${tw`relative  rounded-xl transition-all duration-200 ease-linear w-7 h-1`}
        transform-origin: 1px;
        background-color: ${({ open }) => open ? '#fff' : '#3ebdb0'};

        :first-child {
            transform: ${({ open }) => open ? 'rotate(45deg)' : 'rotate(0)'};
        }

        :nth-child(2) {
            opacity: ${({ open }) => open ? '0' : '1'};
            transform: ${({ open }) => open ? 'translateX(20px)' : 'translateX(0)'};
        }

        :nth-child(3) {
            transform: ${({ open }) => open ? 'rotate(-45deg)' : 'rotate(0)'};
        }
    }
`