import { HeroBanner, About, Contact } from '../components';

function Home() {
    return (
        <div className="home-content">
            <HeroBanner />
            <About />
            <Contact />
        </div>
    );
}

export default Home;