import { Link } from 'react-router-dom';

function NotFound() {
    return (
        <div className="not-found-content">
            <div className="not-found-inner-content text-center min-h-screen flex items-center">
                <div className="container">
                    <h1>404 - Page Not Found!</h1>
                    <Link to="/" className="btn btn-dark">Go Home</Link>
                </div>
            </div>
        </div>
    );
}

export default NotFound;