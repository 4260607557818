import { ProjectList } from '../components';

function Projects() {
    return (
        <div className="projects-content">
            <ProjectList />
        </div>
    );
}

export default Projects;