import styled from "styled-components"
import tw from "twin.macro"

export const HeroBannerStyled = styled.section`
    ${tw`bg-white flex items-center`}
    height: 90vh;

    .hero-inner {
        ${tw`w-full py-5`}
        animation: fadein 1s;
    }

    h1 {
        ${tw`text-secondary`}  

        .name {
            ${tw`text-primary`}  
        }

        .small {
            ${tw`text-xl xl:text-2xl`}
        }
    }
`;
