import { useEffect, useState } from "react";
import { ProjectsStyled } from './project-list.styled';

export const ProjectList = () => {
    const projectsToShow = 3;
    const [visible, setVisible] = useState(projectsToShow);
    const [data, setData] = useState([]);

    const getData = async () => {
        await fetch('projects.json'
            , {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            }
        )
            .then(function (response) {
                return response.json();
            })
            .then(function (myJson) {
                setData(myJson)
            });
    }

    useEffect(() => {
        getData();
    }, []);

    return (
        <ProjectsStyled>
            <div className="projects-inner">
                <div className="container">

                    <div className="section-titles-wrp max-w-5xl m-auto">
                        <h2 className="section-title">Projects</h2>
                        <p className="section-description">Listed are some of the projects done for clients in different parts of the world, where I have worked on individually or with a development team. Credit for each of these listed project's goes to the company and other involved parties responsible for it's development.</p>
                    </div>

                    <div className="projects-content">
                        {data.length > 0 && data.slice(0, visible).map((item, i) => (
                            <div className="project-item" key={i}>
                                <div className="left-col">
                                    <h3>{item.name}</h3>

                                    {item.description &&
                                        <p className="description">{item.description}</p>
                                    }

                                    <div className="technology-used">
                                        <h6 className="">Technologies Used:</h6>
                                        {item.technologies.map((tech) => (
                                            <span key={tech}>{tech}</span>
                                        ))}
                                    </div>

                                    {item.visitUrl &&
                                        <a href={item.visitUrl} rel="noreferrer" target="_blank" className="btn btn-dark">Visit</a>
                                    }
                                </div>

                                <div className="right-col">
                                    {item.image ?
                                        <img src={item.image} alt="project" />
                                        :
                                        <img src="placeholder.png" alt="project" />
                                    }
                                </div>
                            </div>
                        ))}

                        {visible < data.length &&
                            <div className="load-more-wrp">
                                <button onClick={() => setVisible(visible + projectsToShow)} type="button" className="btn btn-dark">
                                    Load More
                                </button>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </ProjectsStyled>
    );
}