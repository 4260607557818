import styled from "styled-components"
import tw from "twin.macro"

export const FooterStyled = styled.footer`
    .footer-inner {
        ${tw`bg-secondary text-white text-center py-20 lg:py-10`}
    }

    .icons-wrp {
        ul {
            ${tw`flex justify-center`} 

            li {
                ${tw`px-4`} 
            }
        }

        svg {
            ${tw`w-6 h-6`}
        }
    }

    .copyright-wrp {
        border-top: 2px solid rgb(255 255 255 / 20%);
        ${tw`mt-5 pt-5`}
    }
`;
