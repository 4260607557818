import styled from "styled-components"
import tw from "twin.macro"

export const AboutStyled = styled.section`
    .about-inner {
        ${tw`bg-primary text-white py-20`}
    }

    .about-content {
        ${tw`grid grid-cols-1 lg:grid-cols-2 gap-8`}
    }

    .image-col {
        .image-col-inner {
            background-image: url('myself.jpeg');
            min-height: 550px;
            ${tw`bg-no-repeat bg-center bg-cover w-full h-full`}
        }
    }

    .content-col {
        p {
            ${tw`text-lg tracking-wider leading-8 mb-7`}

            a {
                ${tw`hover:text-white italic hover:underline`}
            }
        }
  
        .btn-row {
            .btn{
                ${tw`first:mr-3 first:md:mr-6`}
            }
        }
    }
`;
