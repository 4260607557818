import { useRef, useState } from 'react';
import { useForm } from "react-hook-form";
import emailjs from '@emailjs/browser';
import { toast } from 'react-toastify';
import { ContactStyled } from './contact.styled';

export const Contact = () => {
    const form = useRef();
    const [formSubmitting, setFormSubmitting] = useState(false);
    const { register, handleSubmit, formState: { errors }, reset } = useForm();

    const onSubmit = async data => {
        setFormSubmitting(true);
        try {
            await emailjs.sendForm('service_5n3d6zh', 'template_cp3q4im', form.current, 'user_xZYpTCZbPLvumfX9gmr8I');
            toast.success('Inquiry sent!, I will get back to you soon.');
            reset();
        } catch (error) {
            console.error(error);
            toast.error('Error sending message :(');
        }
        setFormSubmitting(false);
    };

    return (
        <ContactStyled>
            <div className="contact-inner">
                <div className="container container-sm">
                    <div className="section-titles-wrp">
                        <h2 className="section-title">Get In Touch!</h2>
                    </div>

                    <div className="form-content">
                        <form ref={form} onSubmit={handleSubmit(onSubmit)}>

                            <div className="form-item">
                                <label>First Name*</label>
                                <input {...register("firstName", { required: true })} placeholder="Enter your first name" />
                                {errors.firstName?.type === 'required' && <span className="field-error">First Name is required</span>}
                            </div>

                            <div className="form-item">
                                <label>Last Name*</label>
                                <input {...register("lastName", { required: true })} placeholder="Enter your last name" />
                                {errors.lastName?.type === 'required' && <span className="field-error">Last Name is required</span>}
                            </div>

                            <div className="form-item">
                                <label>Email*</label>
                                <input type="email" {...register("email", { required: true })} placeholder="Enter your email" />
                                {errors.email?.type === 'required' && <span className="field-error">Email is required</span>}
                            </div>

                            <div className="form-item">
                                <label>Contact Number*</label>
                                <input type="tel" {...register("phone", { required: true })} placeholder="Enter your contact number" />
                                {errors.phone?.type === 'required' && <span className="field-error">Contact number is required</span>}
                            </div>

                            <div className="form-item full-width-form-item">
                                <label>Message*</label>
                                <textarea {...register("message", { required: true })} placeholder="Enter a message" />
                                {errors.message?.type === 'required' && <span className="field-error">Message is required</span>}
                            </div>

                            <div className="form-item full-width-form-item submit-form-item">
                                <input type="submit" value="Submit" className="btn btn-dark" disabled={formSubmitting} />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </ContactStyled>
    );
}