import { Link } from 'react-router-dom'
import { AboutStyled } from './about.styled';

export const About = () => {
    return (
        <AboutStyled id="aboutId">
            <div className="about-inner">
                <div className="container">
                    <div className="section-titles-wrp">
                        <h2 className="section-title">About Me</h2>
                    </div>

                    <div className="about-content">
                        <div className="image-col">
                            <div className="image-col-inner" />
                        </div>

                        <div className="content-col">
                            <p>Hi there! I'm Senal, from the beautiful island of Sri Lanka 🇱🇰. I would describe myself as a laid back person who loves to enjoy a cup of coffee and spend my time surfing the web. I also enjoy spending time going on trips, Basketball 🏀 and Cricket 🏏 are my go to sports to blow off some steam and have some fun.</p>

                            <p>Going to an English school, <a href="https://lyceum.lk/" rel="noreferrer" target="_blank">Lyceum International School</a>, completing my Ordinary Level Cambridge Examinations and starting University right after, that early was a turning point of my life. In 2015 I started my degree in Software Engineering at the <a href="https://www.nsbm.ac.lk/" rel="noreferrer" target="_blank">NSBM University</a> affiliated with the <a href="https://www.plymouth.ac.uk/" rel="noreferrer" target="_blank">University of Plymouth</a></p>

                            <p>Right after completing University during June 2018, I started my Intership at <a href="https://smashydesign.com/" rel="noreferrer" target="_blank">Smashy Design</a>. I worked there from July 2018 to October 2021 as an Intern Front End Engineer, Associate Front End Engineer and Associate Software Engineer during my time there. In November 2021 I joined <a href="https://embla.asia/" rel="noreferrer" target="_blank">Embla Software Innovation</a> as a Associate Software Engineer and have been working there since.</p>

                            <div className="btn-row">
                                <a href="Senal_Dolage_CV.pdf" target="_blank" className="btn btn-light">Check out my CV</a>
                                <Link to="/projects" className="btn btn-light">Projects</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AboutStyled>
    );
}