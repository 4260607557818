import { NavLink } from "react-router-dom";
import { useState } from 'react'
import { HeaderWrapper, LargeHeader, SmallHeader, StyledBurger } from './header.styled';

export const Header = () => {
    const [open, setOpen] = useState(false);

    return (
        <HeaderWrapper>
            <div className="container">
                <LargeHeader>
                    <ul>
                        <li>
                            <NavLink to="/">Home</NavLink>
                        </li>
                        <li>
                            <NavLink to="/projects">Projects</NavLink>
                        </li>
                    </ul>
                </LargeHeader>

                <SmallHeader>
                    <div className="burger-icon-wrp">
                        <StyledBurger
                            open={open}
                            onClick={() => {
                                document.body.classList.toggle("overflow-y-hidden");
                                setOpen(!open)
                            }}
                        >
                            <div />
                            <div />
                            <div />
                        </StyledBurger>
                    </div>

                    <div className={`mobile-menu-content-wrp ${open ? 'menu-opened' : 'menu-closed'}`}>
                        <div className="mobile-menu-content">
                            <ul>
                                <li>
                                    <NavLink to="/" onClick={() => {
                                        document.body.classList.remove("overflow-y-hidden");
                                        setOpen(false)
                                    }}>
                                        Home
                                    </NavLink>
                                </li>
                                <li>
                                    <NavLink to="/projects" onClick={() => {
                                        document.body.classList.remove("overflow-y-hidden");
                                        setOpen(false)
                                    }}>
                                        Projects
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                </SmallHeader>
            </div>
        </HeaderWrapper>
    );
}