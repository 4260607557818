import styled from "styled-components"
import tw from "twin.macro"

export const ProjectsStyled = styled.section`
    .section-titles-wrp {
        ${tw`mt-8`}
    }

    .projects-content {
        ${tw`py-20`}
        animation: fadein 1s;
    }

    .project-item {
        ${tw`grid grid-cols-1 lg:grid-cols-2 gap-10 pb-12 mb-12 border-b-2 border-grey border-dashed last:border-none last:pb-0 last:mb-0`}

        h3 {
            ${tw`mb-4`}
        }

        p {
            ${tw`mb-4`}   
        }

        .technology-used {
            ${tw`flex flex-wrap mb-4`}

            h6 {
                ${tw`w-full`}   
            }

            span  {
                ${tw`pr-2 italic`}
            }
        }

        img {
            ${tw`object-cover md:object-contain object-center w-full`}
            height: 320px;
        }
    }

    .load-more-wrp {
        ${tw`text-center`}
    }
`;
