import styled from "styled-components"
import tw from "twin.macro"

export const ContactStyled = styled.section`
    .contact-inner{
        ${tw`py-20`}
    }

    form {
        ${tw`grid grid-cols-1 md:grid-cols-2 gap-8`}

        .full-width-form-item {
            ${tw`md:col-span-2`}
        }

        .submit-form-item {
            ${tw`text-center`}

            .btn {
                max-width: 160px;
                ${tw`m-auto border-primary`}
            }
        }
    }
`