import ScrollIntoView from "react-scroll-into-view";
import { HeroBannerStyled } from './hero-banner.styled';

export const HeroBanner = () => {
    return (
        <HeroBannerStyled>
            <div className="hero-inner">
                <div className="container">
                    <h1>
                        Hi, my name is <span className="name">Senal Dolage</span>.
                        <br />
                        Your friendly <span className="small">(probably not in the neighbourhood 🕸️)</span> developer.
                    </h1>

                    <ScrollIntoView selector="#aboutId">
                        <button className="btn btn-dark">Know More</button>
                    </ScrollIntoView>
                </div>
            </div>
        </HeroBannerStyled>
    );
}